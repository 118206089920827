.task-list-section{
    padding-bottom: 100px;
}
.task-list-grid{
    display: grid;
    grid-template-columns: 1fr 3fr 1fr 1fr ;
    border: 1px solid black;
}
.task-list-inner .task-list-grid:nth-child(2n)
{
    border-bottom:0 ;
    border-top: 0;
}
.task-list-inner .task-list-grid p:nth-child(2){
    cursor: pointer;
}
.task-list-inner .task-list-grid:nth-child(2n) p{
    border-bottom: 0;
    border-top: 0;
}
.task-list-inner .task-list-grid:last-child{
    border-bottom: 1px solid black !important;
}
.task-list-grid p{
    border: 1px solid black;
    padding: 5px 10px;
    border-radius: 2px;
    margin-bottom: 0;
    word-wrap: break-word;
}
.task-list-grid p:nth-child(2n)
{
    border-left: 0;
    border-right: 0;

}
.task-list-grid input:disabled {
    cursor: default;
    background-color: inherit;
    color: inherit;
    border-color: inherit;
    border : 0;
}

@media( max-width : 576px )
{
    .task-list-grid{
        display: grid;
       
        border: 1px solid black ;
    }
    .task-list-grid p {
        padding: 5px 0px 5px 3px;
        font-size: 13px;

    } 
    .task-list-grid .btn{
        font-size: 12px ;
        padding: 5px;
    }  
}