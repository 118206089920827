.home-component{
    /*background-color: rgb(246, 244, 244) ;*/
    min-height: 100vh;
    position: relative;
}
.showcase-time{
    position: sticky;
    top: 0px;
    z-index: 3;
    border: 0;
    border-radius: 0px;
    box-shadow: 1px -1px 15px -2px rgba(181,175,175,0.75);
    -webkit-box-shadow: 1px -1px 15px -2px rgba(181,175,175,0.75);
}

.section-padding{
    padding: 30px 0;
}
.create-task-section{
    position: relative;
}
@media(max-width : 576px)
{
    .section-padding {
        padding: 50px 0;
    }

    .showcase-time{
        width: 100% !important;
        
    }
    .current-time{
        font-size: 18px !important;
    }

    .timer :is( p ,span ) {
        font-size: 18px !important;
    }
}