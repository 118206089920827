.header-component{
    /* position: sticky;
    top: 0; */
    background-color: var(--bs-primary);
    padding: 15px 0px;
    box-shadow: -1px 5px 28px -1px rgba(0,0,0,0.14);
    -webkit-box-shadow: -1px 5px 28px -1px rgba(0,0,0,0.14);
    -moz-box-shadow: -1px 5px 28px -1px rgba(0,0,0,0.14);
    z-index: 2;
}
.header-logo{
    display: flex;
    gap: 20px;
    cursor: pointer;
}
.header-logo a{
    text-decoration: none !important;
}
.header-logo h1{
    font-size: 35px;
    color: white;
    font-family: 'Pacifico', cursive;
    margin-top: -5px;
}

.nav-links a{
    color: var(--bs-primary);
    background-color: white;
    padding: 10px 20px;
    margin: 0 10px;
    border-radius: 10px;
    transition: var(--simple-transition);
    text-decoration: none;
    text-align: center;
}
.nav-links a:last-child{
    margin-right: 0;
}
.nav-links a:hover{
    box-shadow:  0 0 0px 3px  white;
    background-color: var(--bs-primary);
    color: white;
    border-radius: 12px;
}


@media (min-width : 768px) and (max-width : 991.8px)
{
    .header-component{
        padding: 12px 0px !important;
    }
}

@media (min-width : 576px ) and (max-width : 767.8px)
{
    .header-component{
        padding: 12px 0px;
    }
    .header-logo h1 {
        font-size: 30px;
    }
    .header-logo{
        gap: 10px;
    }
    .header-logo img {
        width: 36px;
    }
    .nav-links :is(a,button){
        font-size: 14px;
        margin: 0;
    }
    .nav-links{
        display: grid;
        grid-template-columns: 1fr 1fr ;
        gap: 5px;
    }
    .nav-links a:nth-child(3){
        grid-column: 1 / span 2;
        text-align: center;
    }
}

@media (max-width : 575.8px){
    .header-component{
        padding: 12px 0px;
    }
    .header-logo{
        gap: 10px;
    }
    .header-logo a h1{
        font-size: 27px;
    }
    .header-logo a img{
        width: 30px;
        display: none;
    }
    .nav-links{
        display: grid;
        grid-template-columns: 1fr 1fr ;
        gap: 5px;
        margin-left: 20px;
    }
    .nav-links :is(a,button){
        padding: 8px 15px;
        font-size: 14px;
        margin: 0;
    }
    .nav-links a:nth-child(3){
        grid-column: 1 / span 2;
        text-align: center;
    }
}