.register-component{
    min-height: 100vh;
    background-color: rgb(246, 244, 244);;
}

.register-form input{
    padding: 4px 10px;
    border: 1px solid rgb(228, 228, 228);
    border-radius: 4px;
}

@media( max-width : 576px )
{
    .register-form input{
        margin: 10px 0;
    }
}