
/*.table-item-row td .react-datetime-picker__wrapper {
    flex-grow: 0;
}*/
.table-grid{
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr ;
    align-items: center;
}

@media ( max-width : 600px) {
    .table-grid div{
        padding-inline-end: 0px !important ;
        font-size: 12px ;
    }
    .table-grid > div:last-child{
        padding-left: 10px !important;
    }
    .table-item-desc-field{
        font-size: 12px !important;
    }
    thead tr{
        display: grid;
        grid-template-columns: 0.5fr 1fr 0.5fr 60px;
    }
    .table-item-row{
        display: grid;
        grid-template-columns: 0.5fr 1fr 0.5fr 60px;
    }
    .tailwind-table-item p{
        font-size: 12px;
    }
    .tail-wind-table-wrapper th {
        font-size: 12px !important;
    }
    .actions-xs div button:first-child {
        margin-right: 0 !important;
    }
    .table-item-row td .react-datetime-picker .react-datetime-picker__wrapper 
    {
        display: block !important;
        flex-shrink: unset !important;
        flex-grow: unset !important;
        padding: 0px !important;
    }
}