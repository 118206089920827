:root{
  --simple-transition : all 300ms ease;
}
*{
  font-family: 'Poppins', sans-serif;
}
.bg-gray{
  /*background-color: rgb(246, 244, 244);*/
  background-color: white !important;
}
.container{
  max-width: 95%;
  margin: 0 auto;
  padding: 0 15px;
}
.history-page{
  min-height: 100vh;
}
.box-component{
  background-color: white;
  /*padding: 20px ;
  border-radius: 20px;*/
}

.loading-wrapper{
  position: fixed;
  inset: 0px ;
  background-color: rgba( 0, 0, 0, 0.2 ); 
  display: flex;
  justify-content: center;
  align-items: center;
}
.cursor-pointer{
  cursor: pointer;
}

@media ( max-width : 992px )
{
  .edit-btn{
    width: 50%;
    font-size: 12px;
  }

  .edit-btn:last-child{
    margin-left: 0 !important;
  }

}
@media ( max-width : 600px )
{
  .edit-btn{
    width: 50%;
    font-size: 11px;
  }

  .edit-btn:last-child{
    margin-left: 0 !important;
  }

}
