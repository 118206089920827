.create-task-inner h2{
    font-size: 25px;
    margin-bottom: 20px;
}

.create-task-fields {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 30px;
}
.task-desc input {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid rgb(228, 228, 228);
    width: 100%;
}
.react-datetime-picker__wrapper {
    border-radius: 4px;
    border: 1px solid rgb(228, 228, 228) !important; 
    padding: 8px;

}
.react-datetime-picker{
    width: 100%;
}
.create-task-component-parent {
    width: 100%;
    position: fixed;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
}
.create-task-component{
    box-shadow: 1px -1px 15px -2px rgba(181,175,175,0.75);
-webkit-box-shadow: 1px -1px 15px -2px rgba(181,175,175,0.75);
padding: 10px;
    /*border: 2px solid var(--bs-primary);*/
}

@media ( max-width : 900px) {
    
}
@media( max-width : 576px )
{
  .create-task-fields {
    grid-template-columns: 1fr 2fr ;
    gap: 3px;
}
.enter-button{
    padding: 5px !important;
    font-size: 13px;
}
.react-datetime-picker{
    font-size: 13px;
}
.react-datetime-picker__button{
    display: none !important;
}
.task-desc input{
    font-size: 13px;
}
}